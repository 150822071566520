.App {
  text-align: center;
  text-indent: 2px;
  justify-content: center;
  display: flex;
  max-width: 100vw;
  max-height: 100vh;
}

.image-body {
  width: 800px;
}
