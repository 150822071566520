.main {
  background-image: linear-gradient(
      to bottom,
      rgba(255, 95, 2, 0.64) 30% 10%,
      rgb(0, 0, 0, 0.64) 70%,
      rgb(0, 0, 0, 0.8) 80%
    ),
    url("../assets/bastette-jpp.jpeg");
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  filter: hue-rotate(360deg);
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 682px) {
  .main{
    width: min-content
  }
}
